<template>
  <!-- {{ inputValue }} -->
  <!-- in caso di debug... -->
  <select
    class="form-select"
    aria-label=""
    :name="name"
    @change="
      myLog($event.target.value);
      handleChange($event);
      $emit(
        'changeSelect',
        $event.target.value === ''
          ? null
          : $event.target.value === 'false'
          ? false
          : $event.target.value === 'true'
          ? true
          : $event.target.value
      );
    "
    :disabled="disabled"
    :id="id"
    @click="setTouched(true)"
  >
    <option
      v-for="option in options"
      :key="Object.values(option)[0]"
      :value="
        option.id_forma_reg ? option.id_forma_reg : Object.values(option)[0]
      "
      :selected="
        Object.values(option)[0] === inputValue
          ? true
          : Object.values(option)[0] === parseInt(value)
          ? true
          : Object.values(option)[1] === inputValue
          ? true
          : Object.values(option)[0] == inputValue // risolve problema comparazione tipi codP
          ? true
          : false
      "
    >
      {{ option.nome ? option.nome : Object.values(option)[1] }}
    </option>
  </select>
  <p
    class="help-message"
    v-show="(errorMessage || meta.valid) && meta.touched"
    :style="style"
    style="color: red"
  >
    {{ errorMessage }}
  </p>
</template>

<script>
const debugPrint = false;
import { useField } from "vee-validate";
import { watch, toRef } from "vue";

export default {
  name: "select-input-nonull",
  emits: ["changeSelect"],
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    campiLocked: {
      type: String,
    },
    style: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    reset: {
      type: Boolean,
    },
  },
  setup(props) {
    const myLog = (e) => {
      // if (debugPrint)
    };

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      resetField,
      setTouched,
    } = useField(toRef(props, "name"));
    // meta.valid = false;
    inputValue.value = toRef(props, "value").value;

    watch(
      () => props.reset,
      () => {
        resetField({
          inputValue: null, // ✅
        });
      }
    );
    watch(
      () => props.value,
      (changedValue, old) => {
        // if (isNaN(changedValue)) {
        //   inputValue.value = null;
        //   return;
        // }
        setTouched(true);
        inputValue.value = changedValue;
      }
    );

    return {
      setTouched,
      resetField,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,

      myLog,
    };
  },
};
</script>

<style scoped>
.form-select {
  height: 28px;
}
</style>
